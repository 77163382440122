// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-men-js": () => import("./../src/pages/men.js" /* webpackChunkName: "component---src-pages-men-js" */),
  "component---src-pages-treatments-and-prices-js": () => import("./../src/pages/treatments-and-prices.js" /* webpackChunkName: "component---src-pages-treatments-and-prices-js" */),
  "component---src-pages-women-js": () => import("./../src/pages/women.js" /* webpackChunkName: "component---src-pages-women-js" */)
}

